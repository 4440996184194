document.addEventListener("DOMContentLoaded", () => {
	const menu = new Mmenu(".mobile-navigation-menu", {
		extensions: [
			"position-back",
			"position-right",
		],
		navbars: [],
		screenReader: {
			aria: true,
			text: true,
		},
	})

	$(".menu-icon").click(function() {
		if ($(".mobile-navigation-menu").hasClass("mm-opened")) {
			menu.API.close()
		}
		else {
			menu.API.open()
		}
	});

	window.addEventListener("scroll", () => {
		let body = document.querySelector("body");
		let primaryNav = document.getElementById("primary-nav");

		body.classList.toggle("translucent-bg", window.scrollY > 50);
		if (window.scrollY > 50) {
			primaryNav.style.display = "none";
		}
		else {
			if (primaryNav != null) {
				primaryNav.style.display = "block";
			}
		}
	});
})
